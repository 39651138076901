


















import {Component, Prop, Vue} from "vue-property-decorator";
import moment from "moment";

@Component({
  components: {},
  props: ["meeting"],
})
export default class ControlPanelMeeting extends Vue {
  @Prop()
  public meeting!: any;

  get startDate() {
    return moment(this.meeting.startdate).format("DD MMMM YYYY");
  }

  get endDate() {
    return moment(this.meeting.enddate).format("DD MMMM YYYY");
  }

  public edit() {
    this.$emit("editMeeting", this.meeting);
  }

  public editFiles() {
    this.$emit("editFiles", this.meeting);
  }
}
