











import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  props: ["files"],
})
export default class ControlPanelMeetingFileList extends Vue {
  @Prop()
  public files: any;

  public deleteFile(file: any) {
    this.$emit("deleteFile", file);
  }
}
