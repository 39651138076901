










import {Component, Prop, Vue} from "vue-property-decorator";
import ControlPanelMeeting from "./ControlPanelMeeting.vue";

@Component({
  components: {
    ControlPanelMeeting,
  },
  props: {
    upcoming: {type: Boolean},
    board: {type: Boolean},
  },
})
export default class ControlPanelMeetingList extends Vue {
  @Prop()
  public upcoming!: boolean;

  @Prop()
  public board: boolean;

  public title = (this.upcoming ? "Upcoming " : "Past ") + (this.board ? "Board meetings" : "meetings");
  private key = this.upcoming ? "upcoming" : "past";

  public created() {
    if (this.board) {
      this.$store.dispatch("boardMeetings/fetchMeetings");
    } else {
      this.$store.dispatch("meetings/fetchMeetings");
    }
  }

  public editMeeting(meeting: any) {
    this.$emit("editMeeting", meeting);
  }

  public editFiles(meeting: any) {
    this.$emit("editFiles", meeting);
  }

  get meetings() {
    if (this.board) {
      return this.$store.state.boardMeetings[this.key];
    }
    return this.$store.state.meetings[this.key];
  }
}
